@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.NotificationTextlinkAd_target-link__16nwc {
  text-decoration: none;
  display: flex;
}

.NotificationTextlinkAd_panel__2AV_9 {
  width: 100%;
  background: #ffffff;
  padding: 14px 16px 13px;
}

.NotificationTextlinkAd_panel__aside__2_mox {
  display: inline-block;
  height: 100%;
  max-width: 36px;
}

.NotificationTextlinkAd_panel__aside__logo__2xDKb {
  width: 36px;
  height: 36px;
	border-radius: 50%;
}

.NotificationTextlinkAd_panel__content__37HmG {
  display: inline-block;
  height: 100%;
  margin-left: 16px;
  width: calc(100% - 36px);
}

.NotificationTextlinkAd_panel__header__s-xZc {
}

.NotificationTextlinkAd_panel__header__title__3HiIK {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NotificationTextlinkAd_panel__header__title__container__2FHEG {
  display: flex;
  align-items: center;
}

.NotificationTextlinkAd_panel__header__title__text__3h1nf {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.NotificationTextlinkAd_panel__header__title__badge__1i6Fz {
  height: 20px;
  background: #f5f5f5;
  color: #616161;
  border-radius: 4px;
  padding: 4px;
  margin-left: 4px;

  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.3px;
}

.NotificationTextlinkAd_panel__header__title__icon__3ewWm {
  margin-left: 5px;
}

.NotificationTextlinkAd_panel__header__description__3EP5F {
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
  color: #424242;
}

.NotificationTextlinkAd_panel__body__1ZMFP {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #9e9e9e;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.NotificationImagelinkAd_target-link__Nz07Z {
  text-decoration: none;
  display: flex;
}

.NotificationImagelinkAd_panel__2458f {
  width: 100%;
  background: #ffffff;
  padding: 14px 16px 13px;
}

.NotificationImagelinkAd_panel__aside__30WVN {
  display: inline-block;
  height: 100%;
  max-width: 36px;
}

.NotificationImagelinkAd_panel__aside__logo__xkrY_ {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.NotificationImagelinkAd_panel__content__12PMT {
  display: inline-block;
  height: 100%;
  margin-left: 16px;
  width: calc(100% - 36px);
}

.NotificationImagelinkAd_panel__header__17qPM {
}

.NotificationImagelinkAd_panel__header__title__KLi2m {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NotificationImagelinkAd_panel__header__title__container__eITKL {
  display: flex;
  align-items: center;
}

.NotificationImagelinkAd_panel__header__title__text__2duG2 {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.NotificationImagelinkAd_panel__header__title__badge__17N8M {
  height: 20px;
  background: #f5f5f5;
  color: #616161;
  border-radius: 4px;
  padding: 4px;
  margin-left: 4px;

  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.3px;
}

.NotificationImagelinkAd_panel__header__title__icon__3FvUm {
  margin-left: 5px;
}

.NotificationImagelinkAd_panel__header__description__3rp5B {
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
  color: #424242;
}

.NotificationImagelinkAd_panel__body__3UCjQ {
  margin-top: 4px;
  position: relative;
  padding-bottom: 33.33%;
}

.NotificationImagelinkAd_panel__body__image__3M-sA {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

  @font-face {
    font-family: "Spoqa Han Sans Neo";
    src: url(/static/media/SpoqaHanSansNeo-Regular.1cac2976.woff2)
      format("woff2");
  }
  
  @font-face {
    font-family: "Spoqa Han Sans Neo";
    src: url(/static/media/SpoqaHanSansNeo-Bold.9749e01e.woff2)
      format("woff2");
    font-weight: bold;
    font-style: normal;
  }
html,
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.Window__LockScroll {
  overflow: hidden !important;
}

