@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html,
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.Window__LockScroll {
  overflow: hidden !important;
}
