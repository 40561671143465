  @font-face {
    font-family: "Spoqa Han Sans Neo";
    src: url("SpoqaHanSansNeo-Regular.woff2")
      format("woff2");
  }
  
  @font-face {
    font-family: "Spoqa Han Sans Neo";
    src: url("SpoqaHanSansNeo-Bold.woff2")
      format("woff2");
    font-weight: bold;
    font-style: normal;
  }