*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.target-link {
  text-decoration: none;
  display: flex;
}

.panel {
  width: 100%;
  background: #ffffff;
  padding: 14px 16px 13px;
}

.panel__aside {
  display: inline-block;
  height: 100%;
  max-width: 36px;
}

.panel__aside__logo {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.panel__content {
  display: inline-block;
  height: 100%;
  margin-left: 16px;
  width: calc(100% - 36px);
}

.panel__header {
}

.panel__header__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.panel__header__title__container {
  display: flex;
  align-items: center;
}

.panel__header__title__text {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.panel__header__title__badge {
  height: 20px;
  background: #f5f5f5;
  color: #616161;
  border-radius: 4px;
  padding: 4px;
  margin-left: 4px;

  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.3px;
}

.panel__header__title__icon {
  margin-left: 5px;
}

.panel__header__description {
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
  color: #424242;
}

.panel__body {
  margin-top: 4px;
  position: relative;
  padding-bottom: 33.33%;
}

.panel__body__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
